<template>
<v-app id="dbp-website" v-resize="onResize">
  <div v-if="isLoading">Loading...</div>
  <div v-else>
    <the-app-bar v-on:langChange="loadLocaleMessages" />
    <the-navigation-drawer />
    <v-main>
      <the-header />
      <the-video v-if="target == 'dbp'" />
      <the-features />
      <the-testimonials />
      <the-pricing :language='language' />
      <the-about-us />
      <the-contact-us />
      <the-footer />
    </v-main>
  </div>
</v-app>
</template>

<script>
import {
    setDocumentDirectionPerLocale,
    //  setDocumentTitle,
    setDocumentLang
} from "@/util/i18n/document"
import { loadLocaleMessagesAsync } from "@/i18n"
export default {
    name: 'App',
    data: () => ({
        isLoading: true,
        language: 'en',
        target: process.env.VUE_APP_DBP_TARGET,
    }),
    components: {
        TheAppBar: () => import("@/layouts/TheAppBar"),
        TheNavigationDrawer: () => import("@/layouts/TheNavigationDrawer"),
        TheFooter: () => import("@/layouts/TheFooter"),
        TheHeader: () => import("@/components/TheHeader"),
        TheVideo: () => import("@/components/TheVideo"),
        TheFeatures: () => import("@/components/TheFeatures"),
        TheTestimonials: () => import("@/components/TheTestimonials"),
        ThePricing: () => import("@/components/ThePricing"),
        TheAboutUs: () => import("@/components/TheAboutUs"),
        TheContactUs: () => import("@/components/TheContactUs"),
    },
    mounted() {
        document.getElementById("dbp-website").firstChild.style.minHeight =
            "calc(100 * var(--vh))";
        document.body.style.minHeight = "-webkit-fill-available";
        document.documentElement.setAttribute("style", "height: 100%; --vh:600px;");
        this.onResize();

        this.loadLocaleMessages(this.$i18n.locale);

        if (this.$socket) {
            var client_info = {};
            client_info.screen_width = window.screen.width;
            client_info.screen_height = window.screen.height;
            client_info.window_width = window.innerWidth;
            client_info.window_height = window.innerHeight;
            this.$socket.emit('socket_website:send_info', client_info);
            this.$socket.emit('socket_website:navigate', 'website_loaded');
        }
    },
    methods: {
        onResize: function() {
            document.documentElement.style.setProperty(
                "--vh",
                window.innerHeight / 100 + "px"
            );
        },
        loadLocaleMessages(locale) {
            this.isLoading = true
            this.language = { code: locale };
            loadLocaleMessagesAsync(locale).then(() => {
                setDocumentLang(locale)
                setDocumentDirectionPerLocale(locale)
                //setDocumentTitle(this.$t("app.title"))
                this.isLoading = false
            })
        },
    },
};
</script>
