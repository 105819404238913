export default {
    ar: 'العربية',
    // af: 'Afrikaans',
    de: 'Deutsch',
    en: 'English',
    es: 'Español',
    fr: 'Française',
    he: 'עִברִית',
    is: 'Íslenska',
    it: 'Italiano',
    nl: 'Nederlands',
    // pl: 'Polskie',
    pp: 'Português',
    ru: 'Русский',
    sv: 'Svenska',
}
