import Vue from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome';

import {
  faAsterisk as fasAsterisk,
  faBriefcase as fasBriefcase,
  faCheck as fasCheck,
  faCircle as fasCircle,
  faEnvelope as fasEnvelope,
  faPhone as fasPhone,
} from '@fortawesome/free-solid-svg-icons';

import {
  faFacebookF as fabFacebookF,
} from '@fortawesome/free-brands-svg-icons';

import {
  faCommentSmile as falCommentSmile,
  faEnvelope as falEnvelope,
  faListAlt as falListAlt,
  faTag as falFaTag,
  faBriefcase as falBriefcase,
  faPhone as falPhone,
  faSignIn as falSignIn,
  faUsers as falUsers,
  faUserPlus as falUserPlus,
} from '@fortawesome/pro-light-svg-icons';

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('font-awesome-layers', FontAwesomeLayers);

library.add(
  fasAsterisk,
  falBriefcase,
  fasBriefcase,
  fasCheck,
  fasCircle,
  falCommentSmile,
  falEnvelope,
  fasEnvelope,
  falListAlt,
  fabFacebookF,
  falFaTag,
  falPhone,
  fasPhone,
  falSignIn,
  falUsers,
  falUserPlus,
)