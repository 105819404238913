import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#46C0EB',
        secondary: '#232323',
      },
    },
  },
  options: { 
    customProperties: true,
  },
})



export default vuetify;
