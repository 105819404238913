import Vue from 'vue';
import App from './App.vue';
import Vuex from 'vuex';
import * as Sentry from '@sentry/vue';
import vuetify from './plugins/vuetify';
import axios from 'axios';

import VueCookies from 'vue-cookies';
import i18n from './i18n';

import breedData from '@/assets/breeds.json';
import countryData from '@/assets/countries.json';
import {
    version
}
    from '../package';

import '@/plugins/fontawesome.js';

Vue.config.productionTip = false;

Sentry.init({
    Vue:         Vue,
    environment: process.env.NODE_ENV,
    dsn:         'https://345882f03dc14f11b0147c8160dcdce1@o395770.ingest.sentry.io/5591663',
});

Vue.prototype.$http = axios;

console.log('DBP version', version);
console.log('ENV', process.env);
console.log('NODE_ENV', process.env.NODE_ENV);
console.log('app', process.env.VUE_APP_DBP_APP_DOMAIN);
console.log('www', process.env.VUE_APP_DBP_WWW_DOMAIN);
console.log('legacy', process.env.VUE_APP_DBP_LEGACY_DOMAIN);

Vue.prototype.$site_url = 'https://' + process.env.VUE_APP_DBP_WWW_DOMAIN;
Vue.prototype.$app_url = 'https://' + process.env.VUE_APP_DBP_APP_DOMAIN;
Vue.prototype.$legacy_url = 'https://' + process.env.VUE_APP_DBP_LEGACY_DOMAIN;

// if (process.env.NODE_ENV === 'production') {
//     Vue.prototype.$site_url = 'https://www.dogbreederpro.com';
//     Vue.prototype.$app_url = 'https://app.dogbreederpro.com';
// }

Vue.use(VueCookies, { expires: '1y', secure: true});

Vue.use(Vuex);
const store = new Vuex.Store({
    state: () => ({
        drawer:    false,
        breeds:    breedData,
        countries: countryData,
    }),
    mutations: {
        drawer_update(state, data) {
            state.drawer = data.drawer;
        },
    },
});

new Vue({
    vuetify,
    i18n,
    store:  store,
    render: h => h(App)
}).$mount('#app');
