import Vue from "vue"
import VueI18n from "vue-i18n"
import getBrowserLocale from "@/util/i18n/get-browser-locale"
import { supportedLocalesInclude } from "./util/i18n/supported-locales"

Vue.use(VueI18n)

function getStartingLocale() {
    const browserLocale = getBrowserLocale({ countryCodeOnly: true })

    if (supportedLocalesInclude(browserLocale)) {
        return browserLocale
    }
    else {
        return process.env.VUE_APP_I18N_LOCALE || "en"
    }
}

const startingLocale = getStartingLocale()

const i18n = new VueI18n({
    locale: startingLocale,
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
    messages: {},
})

const loadedLanguages = []

export function loadLocaleMessagesAsync(locale) {
    if (loadedLanguages.length > 0 && i18n.locale === locale) {
        return Promise.resolve(locale)
    }

    // If the language was already loaded
    if (loadedLanguages.includes(locale)) {
        i18n.locale = locale
        return Promise.resolve(locale)
    }

    // If the language hasn't been loaded yet
    const target = process.env.VUE_APP_DBP_TARGET || 'dbp';
    return import(
        /* webpackChunkName: "locale-[request]" */ `@/i18n/${locale}_${target}.json`
    ).then(messages => {
        i18n.setLocaleMessage(locale, messages.default)

        loadedLanguages.push(locale)

        i18n.locale = locale

        return Promise.resolve(locale)
    })
}

loadLocaleMessagesAsync(startingLocale)

export default i18n
